import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/website/website/src/mdx/templates/default/MDXDefaultLayout.jsx";
import { Notification } from '~/src/components/Typography';
import { Link } from '~/src/components/Link';
export const frontmatter = {
  title: 'Uploadcare User Data Deletion',
  description: 'Uploadcare Privacy Policy describes how we collect, manage, and use information, which may include personal data, you provide on our websites.',
  header: 'User Data Deletion',
  canonical: 'https://uploadcare.com/about/privacy-policy/',
  noindex: true
};
export const _frontmatter = {};
const layoutProps = {
  frontmatter,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Notification mdxType="Notification">
  See our <Link to='/about/privacy-policy/' mdxType="Link">privacy policy </Link>
  to learn about your choices and rights.
    </Notification>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      